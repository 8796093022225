import { useState, useEffect, Suspense } from 'react';
import { Outlet, useLocation, useNavigate, ScrollRestoration } from 'react-router';

import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import Header from './Header';
import Footer from './Footer';
import theme from './theme.js';

import Snackbar from './Snackbar';
import SpinnerOfDoom from '../components/SpinnerOfDoom';
import { CookieBanner } from '../components/CookieBanner';

import useStore from '../store';
import { PERSONAL_AREA_PATHNAME, ROOT_PATHNAME } from '../routes';
import { login, logout, getProfile } from '../utils/authentication';

import data from './data.json';

export default function Root() {
  const { logo, icons, header, footer } = data;

  const navigate = useNavigate();
  const location = useLocation();

  // ACCESS the store
  const profile = useStore((state) => state.profile);
  const updateProfile = useStore((state) => state.updateProfile);
  const isUserLoggedIn = profile?.role === 'customer';

  // SET the local states
  const [showLogin, setShowLogin] = useState(false);

  // NAVIGATE to the personal area if users are logged-in, SHOW the login otherwise
  const onLoginClick = () => {
    if (isUserLoggedIn) {
      navigate(PERSONAL_AREA_PATHNAME);
    } else {
      setShowLogin(true);
    }
  };

  // OVERRIDE the fetch function to handle 403 errors
  useEffect(() => {
    const fetch = window.fetch;

    window.fetch = function () {
      return fetch.apply(this, arguments).then((response) => {
        const isVxApiRequest = response.url.indexOf(process.env.REACT_APP_API) === 0;

        if (isVxApiRequest && response.status === 403) {
          logout();
          navigate(ROOT_PATHNAME);
          setShowLogin(true);
        }

        return response;
      });
    };
  }, []);

  // INITIALIZE the profile on mount
  useEffect(() => {
    initProfile();
  }, []);

  async function initProfile() {
    if (!profile) {
      const profile = await getProfile();
      updateProfile(profile);
    }
  }

  // LOGOUT user and navigate to the base URL
  function logoutUser() {
    logout();
    navigate(ROOT_PATHNAME);
  }

  function loginUser(params) {
    return login({
      ...params,
      navigate,
      updateProfile,
      pageRedirect: PERSONAL_AREA_PATHNAME,
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollRestoration />

      <Header
        logo={logo}
        icons={icons}
        sitemap={header.sitemap}
        links={header.links}
        big={location.pathname === ROOT_PATHNAME}
        light={header.lightLogoPages.includes(location.pathname)}
        login={loginUser}
        logout={isUserLoggedIn ? logoutUser : undefined}
        isUserLoggedIn={isUserLoggedIn}
        showLogin={showLogin}
        setShowLogin={setShowLogin}
        onLoginClick={onLoginClick}
      />

      <Suspense fallback={<SpinnerOfDoom />}>
        <Outlet context={{ onLoginClick }} />
      </Suspense>

      <Footer logo={logo.dark} links={footer.links} />

      <CookieBanner />

      <Snackbar />
    </ThemeProvider>
  );
}
