export async function connectionsLoader() {
  const apiKey = JSON.parse(localStorage.getItem('app-store')).state.profile.apiKey;

  const url = `${process.env.REACT_APP_API}/place?api_key=${apiKey}`;

  return {
    promise: fetch(url).then((res) => res.json()),
  };
}

export async function customerLoader() {
  const apiKey = JSON.parse(localStorage.getItem('app-store')).state.profile.apiKey;

  const url = `${process.env.REACT_APP_API}/customer?api_key=${apiKey}`;

  return {
    promise: fetch(url)
      .then((res) => res.json())
      .then(({ data }) => ({ customer: data[0] })),
  };
}

export async function subscriptionsLoader() {
  const profile = JSON.parse(localStorage.getItem('app-store')).state.profile;

  const fields =
    'id,order_number,state,from_date,object.street,object.street_number,object.apartment_number,service.name,service.bind_time,service.description,service.definition';
  const q = `customer:${profile.id},service.definition:!Home%20Drop`;
  const url = `${process.env.REACT_APP_API}/order?fields=${fields}&q=${q}&api_key=${profile.apiKey}`;

  return {
    promise: fetch(url)
      .then((res) => res.json())
      .then(({ data }) => {
        let connections = data.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.object]: {
              id: curr.object,
              street: curr['object.street'],
              streetNumber: curr['object.street_number'],
              apartmentNumber: curr['object.apartment_number'],
            },
          }),
          {},
        );
        connections = Object.values(connections);

        const subscriptions = data.sort((a, b) => (a['service.name'] > b['service.name'] ? 1 : -1));

        return { subscriptions, connections };
      }),
  };
}

export async function surveyLoader({ params }) {
  const { id } = params;
  const apiKey = JSON.parse(localStorage.getItem('app-store')).state.profile.apiKey;

  const url = `${process.env.REACT_APP_API}/object?q=id:${id}&fields=survey,survey_status&api_key=${apiKey}`;
  const url2 = `${process.env.REACT_APP_API}/object/${id}/file?tag=survey&preview=true&api_key=${apiKey}`;

  return {
    promise: Promise.all([
      fetch(url)
        .then((res) => res.json())
        .then(({ data }) => ({ survey: { ...data[0].survey, status: data[0].survey_status } })),
      fetch(url2)
        .then((res) => res.json())
        .then(({ data }) => data),
    ]).then((values) => {
      console.log({ ...values[0], surveyPictures: values[1] });
      return { ...values[0], surveyPictures: values[1] };
    }),
  };
}

export async function blogLoader() {
  const url = '/blog/list.json';
  const postList = await fetch(url)
    .then((res) => res.json())
    .then(({ posts }) => posts)
    .catch(() => undefined);

  if (postList === undefined) {
    return { promise: [] };
  }

  return {
    promise: Promise.all(
      postList
        .filter(({ published }) => published)
        .map(({ post }) => {
          const url = `/blog/post/${post}.json`;

          return fetch(url)
            .then((res) => res.json())
            .then((data) => ({ ...data, slug: post }));
        }),
    ),
  };
}

export async function blogPostLoader({ params }) {
  const url = `/blog/post/${params.id}.json`;
  return {
    promise: fetch(url)
      .then((res) => res.json())
      .then((data) => data),
  };
}
