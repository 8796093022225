import { lazy, Suspense } from 'react';
import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';
import {
  Await,
  Outlet,
  createBrowserRouter,
  RouterProvider,
  useLoaderData,
  useRevalidator,
} from 'react-router';

import Root from './Root';
import ErrorBoundary from './ErrorBoundary';
import SpinnerOfDoom from './components/SpinnerOfDoom';

import * as routes from './routes';
import {
  blogLoader,
  blogPostLoader,
  customerLoader,
  connectionsLoader,
  subscriptionsLoader,
  surveyLoader,
} from './loaders';

import './index.css';

// START Sentry only in production mode
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    tracesSampleRate: 1.0,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: window.release,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [new Sentry.browserTracingIntegration()],
  });
}

// LAZY LOAD routes
const AboutUs = lazy(() => import('./pages/AboutUs'));
const Business = lazy(() => import('./pages/Business'));
const CancellationPolicy = lazy(() => import('./pages/CancellationPolicy'));
const CookiePolicy = lazy(() => import('./pages/CookiePolicy'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const Coverage = lazy(() => import('./pages/Coverage'));
const DataProtection = lazy(() => import('./pages/DataProtection'));
const Downloads = lazy(() => import('./pages/Downloads'));
const Faq = lazy(() => import('./pages/Faq'));
const FullFibre = lazy(() => import('./pages/FullFibre'));
const Home = lazy(() => import('./pages/Home'));
const Impressum = lazy(() => import('./pages/Impressum'));
const Blog = lazy(() => import('./pages/Blog'));
const BlogPost = lazy(() => import('./pages/BlogPost'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Offers = lazy(() => import('./pages/Offers'));
const PasswordReset = lazy(() => import('./pages/PasswordReset'));
const PersonalArea = lazy(() => import('./pages/PersonalArea'));
const PersonalAreaConnections = lazy(() => import('./pages/PersonalArea/Connections'));
const PersonalAreaConnectionsFallback = lazy(() =>
  import('./pages/PersonalArea/Connections').then((module) => ({
    default: module.ConnectionsFallback,
  })),
);
const PersonalPaymentMethod = lazy(() => import('./pages/PersonalPaymentMethod'));
const PersonalPaymentMethodUpdate = lazy(() => import('./pages/PersonalPaymentMethodUpdate'));
const PersonalProfile = lazy(() => import('./pages/PersonalProfile'));
const PersonalSurvey = lazy(() => import('./pages/PersonalSurvey'));
const PersonalSubscriptions = lazy(() => import('./pages/PersonalSubscriptions'));
const PersonalVoipInfo = lazy(() => import('./pages/PersonalVoipInfo'));
const PropertyOwnerData = lazy(() => import('./pages/PropertyOwnerData'));
const RequestPasswordReset = lazy(() => import('./pages/RequestPasswordReset'));
const Residential = lazy(() => import('./pages/Residential'));
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions'));

// CREATE ROUTER
const router = createBrowserRouter([
  {
    element: <Root />,
    ErrorBoundary,
    children: [
      {
        path: routes.ABOUT_US_PATHNAME,
        element: <AboutUs />,
      },
      {
        path: routes.BLOG_PATHNAME,
        element: <Blog />,
        loader: blogLoader,
        Component() {
          let { promise } = useLoaderData();

          return (
            <Suspense fallback={<SpinnerOfDoom />}>
              <Await resolve={promise}>{(data) => <Blog posts={data} />}</Await>
            </Suspense>
          );
        },
      },
      {
        path: `${routes.BLOG_PATHNAME}/:id`,
        element: <BlogPost />,
        loader: blogPostLoader,
        Component() {
          let { promise } = useLoaderData();
          return (
            <Suspense fallback={<SpinnerOfDoom />}>
              <Await resolve={promise}>{(data) => <BlogPost {...data} />}</Await>
            </Suspense>
          );
        },
      },
      {
        path: routes.BUSINESS_PATHNAME,
        element: <Business />,
      },
      {
        path: routes.CANCELLATION_POLICY_PATHNAME,
        element: <CancellationPolicy />,
      },
      {
        path: routes.COOKIE_POLICY_PATHNAME,
        element: <CookiePolicy />,
      },
      {
        path: routes.CONTACT_US_PATHNAME,
        element: <ContactUs />,
      },
      {
        path: routes.COVERAGE_PATHNAME,
        element: <Coverage />,
      },
      {
        path: routes.DATA_PROTECTION_PATHNAME,
        element: <DataProtection />,
      },
      {
        path: routes.DOWNLOADS_PATHNAME,
        element: <Downloads />,
      },
      {
        path: routes.FAQ_PATHNAME,
        element: <Faq />,
      },
      {
        path: routes.FULL_FIBRE_PATHNAME,
        element: <FullFibre />,
      },
      {
        path: routes.ROOT_PATHNAME,
        element: <Home />,
      },
      {
        path: routes.IMPRESSUM_PATHNAME,
        element: <Impressum />,
      },
      {
        path: routes.OFFERS_PATHNAME,
        element: <Offers />,
      },
      {
        path: routes.PASSWORD_RESET_PATHNAME,
        element: <PasswordReset />,
      },
      {
        path: routes.EMAIL_VERIFICATION_PATHNAME,
        element: <PasswordReset />,
      },
      {
        path: routes.PROPERTY_OWNER_DATA_PATHNAME,
        element: <PropertyOwnerData />,
      },
      {
        path: routes.REQUEST_PASSWORD_RESET_PATHNAME,
        element: <RequestPasswordReset />,
      },
      {
        path: routes.RESIDENTIAL_PATHNAME,
        element: <Residential />,
      },
      {
        path: routes.TERMS_AND_CONDITIONS_PATHNAME,
        element: <TermsAndConditions />,
      },
      // PERSONAL AREA ROUTES
      {
        path: routes.PERSONAL_AREA_PATHNAME,
        children: [
          //
          {
            element: <PersonalArea />,
            children: [
              {
                index: true,
                loader: connectionsLoader,
                Component() {
                  let { promise } = useLoaderData();
                  const revalidator = useRevalidator();

                  const refreshData = () => {
                    setTimeout(() => revalidator.revalidate(), 1000);
                  };

                  return (
                    <Suspense fallback={<PersonalAreaConnectionsFallback />}>
                      <Await resolve={promise}>
                        {({ data }) => (
                          <PersonalAreaConnections connections={data} refreshData={refreshData} />
                        )}
                      </Await>
                    </Suspense>
                  );
                },
              },
            ],
          },
          // All routes under the personal area requiring connections data from /place
          {
            loader: connectionsLoader,
            Component() {
              let { promise } = useLoaderData();

              return (
                <Suspense fallback={<SpinnerOfDoom />}>
                  <Await resolve={promise}>
                    {({ data }) => <Outlet context={{ connections: data }} />}
                  </Await>
                </Suspense>
              );
            },
            children: [
              {
                path: routes.PERSONAL_VOIP_INFO_PATHNAME,
                element: <PersonalVoipInfo />,
              },
              {
                path: routes.PERSONAL_CONTACT_US_PATHNAME,
                element: <ContactUs />,
              },
            ],
          },
          {
            path: routes.PERSONAL_SUBSCRIPTIONS_PATHNAME,
            loader: subscriptionsLoader,
            Component() {
              let { promise } = useLoaderData();
              return (
                <Suspense fallback={<SpinnerOfDoom />}>
                  <Await resolve={promise}>{(data) => <PersonalSubscriptions {...data} />}</Await>
                </Suspense>
              );
            },
          },
          {
            path: routes.PERSONAL_PROFILE_PATHNAME,
            loader: customerLoader,
            Component() {
              let { promise } = useLoaderData();
              return (
                <Suspense fallback={<SpinnerOfDoom />}>
                  <Await resolve={promise}>{(data) => <PersonalProfile {...data} />}</Await>
                </Suspense>
              );
            },
          },
          {
            path: `${routes.PERSONAL_SURVEY_PATHNAME}/:id`,
            loader: surveyLoader,
            Component() {
              let { promise } = useLoaderData();
              return (
                <Suspense fallback={<SpinnerOfDoom />}>
                  <Await resolve={promise}>{(data) => <PersonalSurvey {...data} />}</Await>
                </Suspense>
              );
            },
          },
          {
            path: routes.PERSONAL_PAYMENT_METHOD_PATHNAME,
            element: <PersonalPaymentMethod />,
          },
          {
            path: routes.PERSONAL_PAYMENT_METHOD_UPDATE_PATHNAME,
            element: <PersonalPaymentMethodUpdate />,
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);

// RENDER APP
createRoot(document.getElementById('root')).render(
  <RouterProvider router={router} fallbackElement={<SpinnerOfDoom />} />,
);
