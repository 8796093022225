export const ROOT_PATHNAME = '/';
const CONTACT_US = 'contact-us';

// SET absolute pathnames with the ROOT pathname
export const ABOUT_US_PATHNAME = `${ROOT_PATHNAME}about-us`;
export const BUSINESS_PATHNAME = `${ROOT_PATHNAME}business`;
export const CANCELLATION_POLICY_PATHNAME = `${ROOT_PATHNAME}cancellation-policy`;
export const COOKIE_POLICY_PATHNAME = `${ROOT_PATHNAME}cookie-policy`;
export const CONTACT_US_PATHNAME = `${ROOT_PATHNAME}${CONTACT_US}`;
export const COVERAGE_PATHNAME = `${ROOT_PATHNAME}coverage`;
export const DATA_PROTECTION_PATHNAME = `${ROOT_PATHNAME}data-protection`;
export const DOWNLOADS_PATHNAME = `${ROOT_PATHNAME}downloads`;
export const IMPRESSUM_PATHNAME = `${ROOT_PATHNAME}impressum`;
export const EMAIL_VERIFICATION_PATHNAME = `${ROOT_PATHNAME}email_verification`;
export const FAQ_PATHNAME = `${ROOT_PATHNAME}faq`;
export const FULL_FIBRE_PATHNAME = `${ROOT_PATHNAME}full-fibre`;
export const BLOG_PATHNAME = `${ROOT_PATHNAME}blog`;
export const OFFERS_PATHNAME = `${ROOT_PATHNAME}offers`;
export const PASSWORD_RESET_PATHNAME = `${ROOT_PATHNAME}customer_password`;
export const PROPERTY_OWNER_DATA_PATHNAME = `${ROOT_PATHNAME}property-owner-data`;
export const REQUEST_PASSWORD_RESET_PATHNAME = `${ROOT_PATHNAME}request-password-reset`;
export const RESIDENTIAL_PATHNAME = `${ROOT_PATHNAME}residential`;
export const TERMS_AND_CONDITIONS_PATHNAME = `${ROOT_PATHNAME}terms-and-conditions`;

// Personal area routes
export const PERSONAL_AREA_PATHNAME = `${ROOT_PATHNAME}personal-area`;
export const PERSONAL_PROFILE_PATHNAME = 'profile';
export const PERSONAL_PAYMENT_METHOD_PATHNAME = 'payment-method';
export const PERSONAL_PAYMENT_METHOD_UPDATE_PATHNAME = 'payment-method-update';
export const PERSONAL_SUBSCRIPTIONS_PATHNAME = 'subscriptions';
export const PERSONAL_SURVEY_PATHNAME = 'survey';
export const PERSONAL_VOIP_INFO_PATHNAME = 'voip';
export const PERSONAL_CONTACT_US_PATHNAME = `${CONTACT_US}`;
// export const SERVICE_STATUS_PATHNAME = 'service-status';
// export const SPEED_TEST_PATHNAME = 'speed-test';
