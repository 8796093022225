import { Snackbar, Alert, AlertTitle } from '@mui/material';
import useStore from '../../store';

export default function MySnackbar() {
  // ACCESS the store
  const snackbar = useStore((state) => state.snackbar);
  const setSnackbar = useStore((state) => state.setSnackbar);

  if (snackbar === undefined) {
    return null;
  }

  return (
    <Snackbar
      open
      autoHideDuration={6000}
      sx={{ maxWidth: '700px', top: '0px !important' }}
      onClose={() => setSnackbar(undefined)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        sx={{ width: '100%' }}
        severity={snackbar.severity || 'info'}
        onClose={() => setSnackbar(undefined)}
      >
        {snackbar.title && <AlertTitle>{snackbar.title}</AlertTitle>}
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
}
